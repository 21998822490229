import React from 'react'
import PropTypes from 'prop-types'
import { Layout } from '@leshen/gatsby-theme-contentful'
import { graphql } from 'gatsby'

import { Main } from '@leshen/ui'

import {
  mapRichTextHero,
  mapSections,
} from '@leshen/gatsby-theme-contentful/utils/determiners'

import DoNotSellForm from '../components/DoNotSellForm'

const DoNotSell = ({ data }) => {
  const {
    contentfulPage: { hero },
  } = data

  // add a module to the hero\
  const heroOptions = {
    mapper: mapRichTextHero,
    isImageCritical: true,
  }

  // const sectionOptions = {}

  return (
    <Layout
      data={data}
      main={
        <Main>
          {/* Pass the hero to Leshens map so it behaves like normal when coming from Contentful */}
          {mapSections(hero, heroOptions)}
          <div id="loadingScrollTarget" />
          {/* AvailablePlans will show the package cards based on what is available from the Availability form getting used from the Hero. */}
          <DoNotSellForm />
          {/* Render the rest of the sections from Contentful like normal */}
          {/* {sections &&
            sections.map((section) => mapSections(section, sectionOptions))} */}
        </Main>
      }
    />
  )
}

DoNotSell.propTypes = {
  data: PropTypes.shape({}).isRequired,
}

export default DoNotSell

export const query = graphql`
  query DoNotSell($id: String!) {
    contentfulPage(id: { eq: $id }) {
      ...LeshenPage
    }
    site {
      ...LeshenMetadata
    }
  }
`

import React, { useState } from 'react'
import { Form } from '@leshen/ui'

import {
  VariableContent,
  Typography,
  useMapi,
} from '@leshen/gatsby-theme-leshen'

const { EmilyClient } = require('@clearlink/emily')

const DoNotSellForm = () => {
  const [showSubmissionMessage, setShowSubmissionMessage] = useState(false) // eslint-disable-line no-unused-vars
  const { requestId, sessionPromoCode } = useMapi()
  const promoCode =
    sessionPromoCode === 'SAMPLEPROMO' ? 50256 : sessionPromoCode

  return (
    <div>
      <VariableContent
        style={{ paddingTop: '0' }}
        alignMainContent="center"
        mainContent={
          <Form
            customSubmissionMessage={
              <>
                <Typography variant="">Thanks for your submission!</Typography>
              </>
            }
            formSource="eloqua"
            submitButtonText="Submit"
            submitButtonColor="primary"
            submitButtonOutlined={false}
            submitButtonGradient={false}
            inlineButton={false}
            onSubmit={async ({
              values,
              setShowSubmissionMessage, // eslint-disable-line no-shadow
              setShowSubmissionErrorMessage,
            }) => {
              const mergedValues = {
                ...values,
                brand: 'VIV',
                elqForm: 'viv-do-not-sell',
                origin: 'https://www.vivintsource.com',
                domain: 'https://www.vivintsource.com',
                promo_code: promoCode,
                request_id: requestId,
                source: 'viv-do-not-sell',
              }
              try {
                const emilyClient = new EmilyClient('https://leads.clear.link')
                // const emilyClient = new EmilyClient(
                //   'https://leads.dev.aws.clear.link'
                // )

                const response = await emilyClient.submit(mergedValues)

                if (response.status === 200) {
                  setShowSubmissionMessage(true) // Setting showSubmissionMessage to true here
                } else {
                  setShowSubmissionMessage(false)
                }
              } catch (error) {
                // If an error occurs:
                console.error('Submission error:', error) // eslint-disable-line no-console
                setShowSubmissionMessage(false)
                setShowSubmissionErrorMessage(true)
              }
            }}
            emilyTCPAUpdate={() => {}}
            steps={null}
            showSubmissionMessage={showSubmissionMessage}
            fields={[
              {
                name: 'first_name',
                type: 'text',
                label: 'First Name',
                required: true,
              },
              {
                name: 'last_name',
                type: 'text',
                label: 'Last Name',
                required: true,
              },
              {
                name: 'email_address',
                type: 'email',
                label: 'Email',
                required: true,
              },
            ]}
            isMultiStep={false}
            tcpaCheckbox={false}
            initialStepValues={{}}
          />
        }
      />
    </div>
  )
}

export default DoNotSellForm
